<script setup lang="ts">
import type { SidebarStep } from '@/types/registrationData';

defineOptions({
  name: 'FormSidebar',
});

const props = defineProps({
  steps: {
    type: Array as PropType<SidebarStep[]>,
    required: true,
  },
});

const { stepDataRef } = useRegistrationForm();

const currentStepIndexRef = computed(() => {
  const stepIndex = props.steps.findIndex((step) => step.componentNames.includes(stepDataRef.value.component ?? ''));
  return stepIndex === -1 ? props.steps.length : stepIndex;
});
</script>

<template>
  <div class="form-sidebar">
    <div
      v-for="(step, index) of steps"
      :key="step.title"
      :class="[
        'form-sidebar__step',
        {
          'form-sidebar__step--completed': index < currentStepIndexRef,
        },
      ]"
    >
      <div class="form-sidebar__step-content">
        <Ui2Progress
          v-if="step.componentNames.includes(stepDataRef.component ?? '')"
          type="circle"
          size="sm"
          :progress="60"
        />
        <Ui2Icon v-else-if="index < currentStepIndexRef" name="check" :width="20" :height="20" />
        <div v-else class="form-sidebar__dot"></div>

        <div class="form-sidebar__step-label">
          {{ step.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-sidebar {
  @include v2-text-style(sm);

  display: flex;
  flex-direction: column;
  color: $color-text-body-primary;
  width: 360px;
  max-width: 33%;

  @include v2-apply-upto(mobile) {
    display: none;
  }
}

.form-sidebar__step:not(:last-child):after {
  display: block;
  content: '';
  background-color: $color-border-secondary;
  height: 46px;
  width: 2px;
  margin: $space-8;
}

.form-sidebar__step.form-sidebar__step--completed:after {
  background-color: $color-border-action;
}

.form-sidebar__step-content {
  display: flex;
  align-items: center;
  gap: $space-8;
}

.form-sidebar__dot {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    display: block;
    background-color: $color-text-body-disabled;
    border-radius: 50%;
    width: 6px;
    height: 6px;
  }
}
</style>
